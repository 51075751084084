import React from "react";
import "./App.css";

function App() {
  const handleDownload = (fileName) => {
    if (fileName === "redirect-ios") {
      window.location.href =
        "https://apps.apple.com/us/app/openvpn-connect-openvpn-app/id590379981";
    } else if (fileName === "redirect-android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=net.openvpn.openvpn";
    } else {
      const filePath = process.env.PUBLIC_URL + "/downloads/" + fileName;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="app">
      <div className="logo-container text-center my-4">
        <img
          src="images/bartar-logo.png"
          alt="Bartar Group Logo"
          className="logo mx-auto"
        />
        <h1 className="text-2xl font-bold">Bartar Group</h1>
      </div>
      <div className="downloads grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-4">
        {/* Certificate OVPN */}
        <div className="download-item bg-white p-4 shadow rounded text-center">
          <img src="images/certificate.png" alt="App 1" className="mx-auto mb-2" />
          <h2 className="text-lg font-semibold mb-2">Config</h2>
          <button
            className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded"
            onClick={() => handleDownload("bartar.ovpn")}
          >
            Download
          </button>
        </div>
        {/* Android OpenVPN */}
        <div className="download-item bg-white p-4 shadow rounded text-center">
          <img src="images/android-logo.png" alt="App 2" className="mx-auto mb-2" />
          <h2 className="text-lg font-semibold mb-2">Android OpenConnect</h2>
          <button
            className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded"
            onClick={() => handleDownload("openvpn.apk")}
          >
            Download
          </button>
        </div>
        {/* Android SSTP */}
        <div className="download-item bg-white p-4 shadow rounded text-center">
          <img src="images/sstp.jpg" alt="App 3" className="mx-auto mb-2" />
          <h2 className="text-lg font-semibold mb-2">Android - SSTP</h2>
          <button
            className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded"
            onClick={() => handleDownload("sstp.apk")}
          >
            Download
          </button>
        </div>
        {/* iOs OpenVPN */}
        <div className="download-item bg-white p-4 shadow rounded text-center">
          <img src="images/apple-logo.png" alt="App 4" className="mx-auto mb-2" />
          <h2 className="text-lg font-semibold mb-2">iOs - OpenConnect</h2>
          <button
            className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded"
            onClick={() => handleDownload("redirect-ios")}
          >
            Download
          </button>
        </div>
        {/* Windows OpenVPN */}
        <div className="download-item bg-white p-4 shadow rounded text-center">
          <img src="images/windows.png" alt="Windows" className="mx-auto mb-2" />
          <h2 className="text-lg font-semibold mb-2">Windows - OpenConnect</h2>
          <button
            className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded"
            onClick={() => handleDownload("openvpn.msi")}
          >
            Download
          </button>
        </div>
        {/* Windows PBK */}
        <div className="download-item bg-white p-4 shadow rounded text-center">
          <img src="images/windows.png" alt="Windows" className="mx-auto mb-2" />
          <h2 className="text-lg font-semibold mb-2">Windows - PBK</h2>
          <button
            className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded"
            onClick={() => handleDownload("bartar.pbk")}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
  
}

export default App;
